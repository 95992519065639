import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
const title = "医用影像档案信息查询";
const subTitle =
  "通过对患者基本信息（姓名、年龄、病历号等）与影像数据的关联整合，确保每一份影像档案都具有清晰的标识，方便后续查询与调用。";
const placeholder = "请输入档案号、用户姓名或手机号进行搜索";
const Search = (props) => {
  const { search, setSearch, searchFn } = props;
  return (
    <div className={styles.search}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subTitle}>{subTitle}</div>
      <div className={styles.searchBox}>
        <Space.Compact size="large" style={{ width: "100%" }}>
          <Input
            placeholder={placeholder}
            value={search.search_query}
            onChange={(e) =>
              setSearch({
                ...search,
                search_query: e.target.value,
              })
            }
          ></Input>
          <Button type="primary" icon={<SearchOutlined />} onClick={searchFn}>
            搜索
          </Button>
        </Space.Compact>
      </div>
    </div>
  );
};
export default Search;
