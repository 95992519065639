import {
  getCustomers,
  queryUser,
  getAestheticTypes,
  getVisitHistory,
} from "@/service";
import store from "@/store";
export const getCustomer = async ({
  customer_id,
  search_query,
  euid,
  name,
}) => {
  let res = {};
  const params = { page: 1, page_size: 1 };
  if (customer_id) {
    params.id = customer_id;
    res = await getCustomers(params);
    return res?.data?.[0] ?? {};
  }
  if (name) {
    params.name = name;
    params.order = 1;
    res = await getCustomers(params);
    return res?.data?.[0] ?? {};
  } else if (search_query) {
    if (search_query) {
      if (/[\u4e00-\u9fa5]/.test(search_query)) {
        params.name = search_query;
      } else if (search_query.startsWith("DA-")) {
        params.euid = search_query;
      } else {
        params.phone_number = search_query;
      }
    }
    res = await getCustomers(params);
    return res?.data?.[0] ?? {};
  } else if (euid) {
    params.euid = euid;
    params.page = 1;
    params.page_size = 9999;
    res = await getCustomers(params);
    return res?.data ?? [];
  }
};

export const getUsers = async () => {
  const res = await queryUser({});
  const list = res?.data ?? [];
  store.dispatch({
    type: "system/setState",
    payload: {
      users: list,
    },
  });
};

export const getBeautyTypes = async () => {
  const _list = await getAestheticTypes({});
  const list = [];
  _list.forEach((item) => {
    const { area } = item;
    const child = {
      label: item.project,
      value: item.project,
      photos: item.photo_requirements?.split(",") ?? [],
      amount: Number(item?.amount ?? 0),
    };
    const findItem = list.find((item) => item.value === area);
    if (findItem) {
      findItem.children.push(child);
    } else {
      list.push({
        label: area,
        value: area,
        children: [child],
      });
    }
  });
  store.dispatch({
    type: "system/setState",
    payload: {
      beautyTypes: list,
    },
  });
};

// 临时，存 一下客户列表
// export const getCustomerList = async () => {
//   const { data } = await getCustomers({ page: 1, page_size: 999999 });
//   const customerListMap = {};
//   data.forEach((item) => {
//     customerListMap[item.id] = item.customer_name;
//   });
//   console.log(customerListMap, data);
//   store.dispatch({
//     type: "system/setState",
//     payload: {
//       customerListMap: customerListMap,
//     },
//   });
// };

export const getHistory = async ({ euid }) => {
  const params = {};
  params.euid = euid;
  params.page = 1;
  params.page_size = 9999;
  const res = await getVisitHistory(params);
  return res?.data ?? [];
};
