// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g7pi8OjV8e9gaHtWMN16{width:0;height:0}.g7pi8OjV8e9gaHtWMN16.Ocy5iho32wBOMv34tKIA{margin-right:var(--box-padding)}.g7pi8OjV8e9gaHtWMN16.WSV3ibvaGXDqrraLvslr{margin-bottom:var(--box-padding)}`, "",{"version":3,"sources":["webpack://./src/components/Distance/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,OAAA,CACA,QAAA,CACA,2CACE,+BAAA,CAEF,2CACE,gCAAA","sourcesContent":[".box {\r\n  width: 0;\r\n  height: 0;\r\n  &.row {\r\n    margin-right: var(--box-padding);\r\n  }\r\n  &.col {\r\n    margin-bottom: var(--box-padding);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `g7pi8OjV8e9gaHtWMN16`,
	"row": `Ocy5iho32wBOMv34tKIA`,
	"col": `WSV3ibvaGXDqrraLvslr`
};
export default ___CSS_LOADER_EXPORT___;
