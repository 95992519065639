// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --bg: #ecf3fd;
  --box-bg: #fff;

  --title-color: #12448c;
  --title-sub-color: #12448c;

  --title-color-b: #2c3135;

  --card-color: #fff;

  --active-color-bg: #e1e6ff;
  --active-color: #12448c;

  --box-padding: 20px;
  --box-padding-small: calc(var(--box-padding) / 1.6);
  --box-card-padding-top: 50px;

  --text-btn-color: #565e6a;
  --text-btn-color-hover: #fff;
  --text-btn-color-active: #fff;

  --text-btn-bg-active: #1b82e4;
  --text-btn-bg-hover: #679eeb;
  --text-btn-bg: #fff;

  --text-color: #212121;
  --text-color-light: #9198a3;

  --search-height: 213px;

  --tool-bg: #8095b3;
}
.ant-tag {
  margin-inline-end: 0;
  height: 28px;
  line-height: 28px;

  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  font-style: normal;
}
.ant-tag + .ant-tag {
  margin-inline-start: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/root.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;;EAEd,sBAAsB;EACtB,0BAA0B;;EAE1B,wBAAwB;;EAExB,kBAAkB;;EAElB,0BAA0B;EAC1B,uBAAuB;;EAEvB,mBAAmB;EACnB,mDAAmD;EACnD,4BAA4B;;EAE5B,yBAAyB;EACzB,4BAA4B;EAC5B,6BAA6B;;EAE7B,6BAA6B;EAC7B,4BAA4B;EAC5B,mBAAmB;;EAEnB,qBAAqB;EACrB,2BAA2B;;EAE3B,sBAAsB;;EAEtB,kBAAkB;AACpB;AACA;EACE,oBAAoB;EACpB,YAAY;EACZ,iBAAiB;;EAEjB,oCAAoC;EACpC,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[":root {\r\n  --bg: #ecf3fd;\r\n  --box-bg: #fff;\r\n\r\n  --title-color: #12448c;\r\n  --title-sub-color: #12448c;\r\n\r\n  --title-color-b: #2c3135;\r\n\r\n  --card-color: #fff;\r\n\r\n  --active-color-bg: #e1e6ff;\r\n  --active-color: #12448c;\r\n\r\n  --box-padding: 20px;\r\n  --box-padding-small: calc(var(--box-padding) / 1.6);\r\n  --box-card-padding-top: 50px;\r\n\r\n  --text-btn-color: #565e6a;\r\n  --text-btn-color-hover: #fff;\r\n  --text-btn-color-active: #fff;\r\n\r\n  --text-btn-bg-active: #1b82e4;\r\n  --text-btn-bg-hover: #679eeb;\r\n  --text-btn-bg: #fff;\r\n\r\n  --text-color: #212121;\r\n  --text-color-light: #9198a3;\r\n\r\n  --search-height: 213px;\r\n\r\n  --tool-bg: #8095b3;\r\n}\r\n.ant-tag {\r\n  margin-inline-end: 0;\r\n  height: 28px;\r\n  line-height: 28px;\r\n\r\n  font-family: PingFangSC, PingFang SC;\r\n  font-weight: 500;\r\n  font-size: 14px;\r\n  text-align: left;\r\n  font-style: normal;\r\n}\r\n.ant-tag + .ant-tag {\r\n  margin-inline-start: 6px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
