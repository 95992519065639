import styles from "./index.module.scss"; // 引入 SCSS 文件
import { Input, Button, Card, Form, Space } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { login } from "@/service";
import { useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { generateCaptcha } from "@/utils/function";
const LoginPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const canvasRef = useRef(null);
  async function submit() {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const params = form.getFieldsValue();
        const res = await login(params);
        const [data] = res;
        window.localStorage.setItem("userInfo", JSON.stringify(data));
        dispatch({
          type: "user/setState",
          payload: {
            username: data.username,
            name: data.name,
            userId: data.id,
            token: data.token,
            is_admin: data.is_admin,
          },
        });
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  function initCode() {
    setCode(generateCaptcha(canvasRef.current));
  }
  useEffect(() => {
    initCode();
  }, []);
  return (
    <div className={styles.box}>
      <div className={styles.loginBox}>
        <h1 className={styles.title}>医用影像档案管理系统</h1>
        <h2 className={styles.subTitle}>
          Medical Image Archive Management System
        </h2>
        <Card>
          <div className={styles.formBox}>
            <Form form={form} size="large" layout="vertical" onFinish={submit}>
              <Form.Item
                name="username"
                rules={[{ required: true, message: "请输入用户名" }]}
              >
                <Input prefix={<UserOutlined />} placeholder="请输入用户名" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="请输入密码"
                />
              </Form.Item>
              <Form.Item>
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    noStyle
                    name="captcha"
                    rules={[
                      { required: true, message: "请输入验证码" },
                      {
                        validator: (_, value) => {
                          // 小写
                          if (value.toLowerCase() === code.toLowerCase())
                            return Promise.resolve();
                          return Promise.reject(new Error("验证码错误"));
                        },
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "50%" }}
                      placeholder="请输入验证码"
                    />
                  </Form.Item>
                  <canvas
                    onClick={initCode}
                    ref={canvasRef}
                    className={styles.canvas}
                  ></canvas>
                </Space.Compact>
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  style={{ width: "100%" }}
                  htmlType="submit"
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
