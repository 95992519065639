// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import userInfo from "./modules/userInfo";
import system from "./modules/system";
import history from "./modules/history";

const store = configureStore({
  reducer: {
    userInfo,
    system,
    history,
  },
});

export default store;
