import styles from "./index.module.scss";
export default ({ col = true, row = false }) => {
  const style = {};
  if (Object.prototype.toString.call(col) === "[object Number]") {
    style.marginBottom = `${col}px`;
  }
  if (Object.prototype.toString.call(row) === "[object Number]") {
    style.marginRight = `${row}px`;
  }
  return (
    <div
      className={`${styles.box} ${col ? styles.col : ""} ${
        row ? styles.row : ""
      }`}
      style={style}
    />
  );
};
