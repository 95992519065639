import { post, get, upload } from "@/utils/request";

// 登录
export const login = async (data) => {
  return post("/api/login", data);
};

// 创建更新用户
export const upsertUser = async (data) => {
  return post("/api/upsertUser", data);
};
// 获取用户
export const queryUser = async (data) => {
  return post("/api/queryUser", data);
};
// 删除用户
export const deleteUser = async (data) => {
  return post("/api/deleteUser", data);
};
// 获取就诊历史
export const getVisitHistory = async (data) => {
  return post("/api/getVisitHistory", data);
};
// 新增/更新就诊
export const upsertVisitRecord = async (data) => {
  return post("/api/upsertVisitRecord", data);
};

// 标注
export const labelVisitPhoto = async (data) => {
  return post("/api/labelVisitPhoto", data);
};

// 新增更新医美类型
export const upsertAestheticTypes = async (data) => {
  return post("/api/upsertAestheticTypes", data);
};

// 获取医美类型
export const getAestheticTypes = async (data) => {
  return post("/api/getAestheticTypes", data);
};

// 新增更新医美类型
export const deleteAestheticTypes = async (data) => {
  return post("/api/deleteAestheticTypes", data);
};

// 新增/更新客户资料接口
export const upsertCustomer = async (data) => {
  return post("/api/upsertCustomer", data);
};

// 获取客户接口
export const getCustomers = async (data) => {
  return post("/api/getCustomers", data);
};

// 照片上传
export const uploadVisitPhotos = async (data, option) => {
  return upload("/api/uploadVisitPhotos", data, option);
};
// 获取照片
export const queryPhoto = async (data) => {
  return post("/api/queryPhoto", data);
};

// 删除照片
export const deletePhoto = async (data) => {
  return post("/api/deletePhoto", data);
};

// 标注接口
export const queryLabel = async (data) => {
  return post("/api/queryLabel", data);
};

export const updateLabel = async (data) => {
  return post("/api/updateLabel", data);
};

export const addLabel = async (data) => {
  return post("/api/addLabel", data);
};

export const deleteLabel = async (data) => {
  return post("/api/deleteLabel", data);
};
