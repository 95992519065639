// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/font/PingFang.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: var(--bg);
}

// 所有滚动条美化
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #8e8e8e;
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background-color: #864e4e;
  border-radius: 4px;
}
body {
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
}

@font-face {
  font-family: "PingFang-SC";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype"); /* 根据字体文件类型调整 format */
  font-weight: normal;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;;EAEE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,yDAAyD;AAC3D;;AAEA;EACE,0BAA0B;EAC1B,+DAA0D,EAAE,sBAAsB;EAClF,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["html,\r\nbody {\r\n  margin: 0;\r\n  padding: 0;\r\n  min-height: 100vh;\r\n  background-color: var(--bg);\r\n}\r\n\r\n// 所有滚动条美化\r\n*::-webkit-scrollbar {\r\n  width: 8px;\r\n  height: 8px;\r\n}\r\n\r\n*::-webkit-scrollbar-thumb {\r\n  background-color: #8e8e8e;\r\n  border-radius: 4px;\r\n}\r\n\r\n*::-webkit-scrollbar-track {\r\n  background-color: #864e4e;\r\n  border-radius: 4px;\r\n}\r\nbody {\r\n  font-family: \"PingFang SC\", \"Microsoft YaHei\", sans-serif;\r\n}\r\n\r\n@font-face {\r\n  font-family: \"PingFang-SC\";\r\n  src: url(\"../assets/font/PingFang.otf\") format(\"truetype\"); /* 根据字体文件类型调整 format */\r\n  font-weight: normal;\r\n  font-style: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
