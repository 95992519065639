// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PamhhLIAvixo7oAf804n{display:grid;grid-template-columns:repeat(auto-fit, 582px);grid-template-rows:repeat(auto-fit, 330px);gap:20px}`, "",{"version":3,"sources":["webpack://./src/pages/Index/components/content/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6CAAA,CACA,0CAAA,CAEA,QAAA","sourcesContent":[".content {\r\n  display: grid;\r\n  grid-template-columns: repeat(auto-fit, 582px);\r\n  grid-template-rows: repeat(auto-fit, 330px);\r\n  // justify-content: space-between;\r\n  gap: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `PamhhLIAvixo7oAf804n`
};
export default ___CSS_LOADER_EXPORT___;
