import { createSlice } from "@reduxjs/toolkit";
const counterSlice = createSlice({
  name: "system",
  initialState: {
    routeShow: true,
    // 当前工具点了什么
    toolAction: "",
    // 所有用户数据
    users: [],
    // 医美类型
    beautyTypes: [],
    // 临时处理，存一下客户列表生成的对象
    customerListMap: {},
  },
  reducers: {
    setState(state, { payload }) {
      for (let key in payload) {
        state[key] = payload[key];
      }
    },
  },
});

export const {} = counterSlice.actions;
export default counterSlice.reducer;
