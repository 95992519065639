import styles from "./item.module.scss";
import { Card, Divider, Button } from "antd";
import events from "@/utils/events";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { KH } from "@/options";
import { useEffect, useState, useRef } from "react";
import { queryPhoto } from "@/service";
import { getImgBaseUrl, errorSrc } from "@/utils/function";

const column = [
  {
    title: "",
    key: "euid",
    className: styles.bold,
  },
  {
    title: "客户姓名：",
    key: "customer_name",
    className: styles.bold,
  },
  {
    title: "性别：",
    key: "gender",
  },
  {
    title: "联系电话：",
    key: "phone_number",
  },
  {
    title: "是否做过医美：",
    key: "past_aesthetic_history",
  },
  {
    title: "是否生育：",
    key: "has_children",
  },
  {
    title: "紧急联系人：",
    key: "emergency_contact_name",
  },
  {
    title: "紧急电话：",
    key: "emergency_contact_phone",
  },
];
const CustormItem = (props) => {
  const { data: _data, isLoadedEnd, next, isEnd } = props;
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const domRef = useRef();
  function edit() {
    events.emit("custorm-form-open", data);
  }
  function jumpTo() {
    dispatch({
      type: "history/setState",
      payload: {
        tabActiveKey: KH,
      },
    });
    navigate(`/history?customer_id=${_data.id}`);
  }
  async function getPhote(person) {
    const [photo] = await queryPhoto({
      customer_id: _data.id,
      isCover: 2,
      photo_requirements: "头像",
    });
    const res = {
      ...person,
      cover_path: `${getImgBaseUrl()}${photo?.photo_url}`,
      img_id: photo?.id,
    };
    setData(res);
  }
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          isEnd && !isLoadedEnd && next();
          getPhote(_data);
          observer.unobserve(domRef.current);
          observer?.disconnect();
        }
      });
    });

    if (domRef.current) {
      observer?.observe(domRef.current);
    }
    return () => observer?.disconnect();
  }, [_data]);
  return (
    <Card className={styles.card} ref={domRef}>
      <div className={`${styles.box}`}>
        <div className={styles.top}>
          <div className={styles.coverBox}>
            <img
              src={data.cover_path}
              alt=""
              onError={(e) => (e.target.src = errorSrc)}
            />
          </div>
          <div className={styles.infoBox}>
            {column.map((item, index) => {
              return (
                <div className={`${styles.info} ${item.className}`} key={index}>
                  <div>{item.title}</div>
                  <div>{data?.[item.key]}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.bottom}>
          {/* {`${data.province ?? ""}${data.city ?? ""}${data.district ?? ""}${
            data.detailed_address ?? ""
          }`} */}
          {data.medical_records}
        </div>
        <Divider></Divider>
        <div className={styles.btn}>
          <Button
            color="primary"
            onClick={edit}
            variant="solid"
            style={{ marginRight: "10px" }}
          >
            基本信息
          </Button>
          <Button type="primary" onClick={() => jumpTo("/history")}>
            详情信息
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default CustormItem;
