// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rD9xYFGKWNKXVh4LFaLA{padding:var(--box-padding);box-sizing:border-box;width:100%;height:100%;display:flex;flex-direction:column;position:relative;overflow:hidden}.rD9xYFGKWNKXVh4LFaLA .rdepqLqUtfDtdrxCRoNU{flex:1;width:100%;height:0;overflow:overlay}`, "",{"version":3,"sources":["webpack://./src/pages/Index/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,eAAA,CACA,4CACE,MAAA,CACA,UAAA,CACA,QAAA,CACA,gBAAA","sourcesContent":[".box {\r\n  padding: var(--box-padding);\r\n  box-sizing: border-box;\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  position: relative;\r\n  overflow: hidden;\r\n  .contentBox {\r\n    flex: 1;\r\n    width: 100%;\r\n    height: 0;\r\n    overflow: overlay;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `rD9xYFGKWNKXVh4LFaLA`,
	"contentBox": `rdepqLqUtfDtdrxCRoNU`
};
export default ___CSS_LOADER_EXPORT___;
