// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zABGthQbrgQX1MhHi1if{width:100%;display:flex;justify-content:space-between;user-select:none;align-items:center}.zABGthQbrgQX1MhHi1if .QHE_C_mOFXVZIOARAAFk{font-family:PingFang-SC,PingFang-SC;font-weight:400;font-size:15px;color:var(--text-btn-color);line-height:21px}`, "",{"version":3,"sources":["webpack://./src/pages/Index/components/totalMsg/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,gBAAA,CACA,kBAAA,CACA,4CACE,mCAAA,CACA,eAAA,CACA,cAAA,CACA,2BAAA,CACA,gBAAA","sourcesContent":[".totalMsg {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  user-select: none;\r\n  align-items: center;\r\n  .leftMsg {\r\n    font-family: PingFang-SC, PingFang-SC;\r\n    font-weight: 400;\r\n    font-size: 15px;\r\n    color: var(--text-btn-color);\r\n    line-height: 21px;\r\n  }\r\n  .rightMsg {\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalMsg": `zABGthQbrgQX1MhHi1if`,
	"leftMsg": `QHE_C_mOFXVZIOARAAFk`
};
export default ___CSS_LOADER_EXPORT___;
