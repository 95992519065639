import dakai from "./1.png";
import fangda from "./2.png";
import fenxiang from "./3.png";
import jinggao from "./4.png";
import quanping from "./5.png";
import baocun from "./6.png";
import suoxiao from "./7.png";
import bidui from "./8.png";
import biaozhu from "./9.png";
import yidong from "./10.png";
import fuzhi from "./11.png";
import zheyan from "./12.png";
import shouye from "./首页.png";
import quanping2 from "./全屏.png";
import xinjian from "./新建.png";
import remove from "./remove.png";
import shiqu from "./shiqu.png";
import buwei from "./部位.jpg";
import zhengxingxiangmu from "./整形项目.jpg";
export default {
  dakai,
  fangda,
  fenxiang,
  jinggao,
  quanping,
  baocun,
  suoxiao,
  bidui,
  biaozhu,
  yidong,
  fuzhi,
  zheyan,
  // header
  shouye,
  quanping2,
  xinjian,
  remove,
  shiqu,
  buwei,
  zhengxingxiangmu,
};
