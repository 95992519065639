import { useEffect, useMemo } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import events from "@/utils/events";
import { useSelector, useDispatch } from "react-redux";
import { getUsers, getBeautyTypes } from "@/action";
import { getRoute } from "@/routes/routes";
import { message } from "antd";
const PrivateRoute = ({ children }) => {
  const { username, is_admin } = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const canJump = useMemo(() => {
    const routes = getRoute().allRoutes;
    if (+is_admin == 1) {
      return true;
    } else if (+is_admin == 0) {
      const find = routes
        .filter((item) => !item.isAdmin)
        .find((item) => item.path == location.pathname);
      if (find) return true;
      return false;
    }
  }, [location]);
  function logout() {
    window.localStorage.removeItem("userInfo");
    dispatch({ type: "userInfo/logout" });
    navigate("/login");
  }
  useEffect(() => {
    getUsers();
    getBeautyTypes();
    events.on("logout", logout);
    return () => {
      events.off("logout", logout);
    };
  }, []);
  if (!canJump) message.warning("没有权限访问");
  return canJump && username ? (
    // 可以跳转有用户
    children
  ) : canJump && !username ? (
    // 可以跳转没有用户
    <Navigate to="/login" />
  ) : !canJump && !username ? (
    // 不能跳转也没用户
    <Navigate to="/login" />
  ) : !canJump && username ? (
    // 不能跳转有用户
    <Navigate to="/index" />
  ) : (
    <></>
  );
};

export default PrivateRoute;
