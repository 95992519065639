import { Outlet } from "react-router-dom";
import Header from "./header";
import Routes from "./routes";
import styles from "./index.module.scss";
import { HeaderProvider } from "./HeaderContext";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { getRoute } from "@/routes/routes";
const Layout = () => {
  const location = useLocation();
  // 路由是否显示
  const showRoute = useMemo(() => {
    const routes = getRoute().allRoutes;
    const find = routes.find((item) => item.path === location.pathname);
    return !!find?.routeHidden ?? true;
  }, [location.pathname]);

  return (
    <HeaderProvider>
      <div className={styles.layout}>
        <Header />
        <div className={styles.contentBox}>
          {!showRoute && (
            <div className={styles.left}>
              <Routes />
            </div>
          )}
          <div className={styles.rightContentBox}>
            <Outlet />
          </div>
        </div>
      </div>
    </HeaderProvider>
  );
};

export default Layout;
