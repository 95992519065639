import React from "react";
import ReactDOM from "react-dom/client";
import Router from "@/routes";
import "@/styles/index.scss";
import { ConfigProvider } from "antd";
import store from "@/store";
import { Provider } from "react-redux";

const theme = {
  token: {
    // colorPrimary: "#12448C",
  },
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <ConfigProvider theme={theme}>
      <Provider store={store}>
        <Router />
      </Provider>
    </ConfigProvider>
  // </React.StrictMode>
);
