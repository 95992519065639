import { createSlice } from "@reduxjs/toolkit";
const userInfoJson = window.localStorage.getItem("userInfo");
const userInfo = userInfoJson
  ? JSON.parse(userInfoJson)
  : { username: "", name: "", id: "", token: "", is_admin: 0 };
const counterSlice = createSlice({
  name: "user",
  initialState: userInfo,
  reducers: {
    setState(state, { payload }) {
      for (let key in payload) {
        state[key] = payload[key];
      }
    },
    logout(state) {
      state = {
        username: "",
        id: "",
        token: "",
        is_admin: 0,
      };
    },
  },
});

export const {} = counterSlice.actions;
export default counterSlice.reducer;
