import styles from "./index.module.scss";
import { Radio } from "antd";
const options = [
  {
    label: "医美档案",
    value: "ym",
  },
  {
    label: "客户档案",
    value: "kh",
  },
];
const TotalMsg = (props) => {
  const { total, setView, view } = props;
  return (
    <div className={styles.totalMsg}>
      <div className={styles.leftMsg}>为您找到符合条件的{total}条记录</div>
      <div className={styles.rightMsg}>
        {/* <Radio.Group
          onChange={(e) => setView(e.target.value)}
          value={view}
          block
          optionType="button"
          options={options}
          buttonStyle="solid"
        /> */}
      </div>
    </div>
  );
};
export default TotalMsg;
