import axios from "axios";
import events from "@/utils/events";
import { message } from "antd";
function getUserInfo() {
  const userInfoJson = window.localStorage.getItem("userInfo");
  const userInfo = userInfoJson ? JSON.parse(userInfoJson) : null;
  return userInfo;
}
const service = axios.create({
  // baseURL: "/api", // 设置你的基础 URL，可以是相对路径或绝对路径
  // timeout: 5000, // 设置超时时间，单位毫秒
  headers: {
    "Content-Type": "application/json;charset=UTF-8", // 设置请求头
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    const userInfo = getUserInfo();
    if (userInfo) {
      config.headers.Authorization = userInfo.token;
      config.headers.username = userInfo.username;
    }
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    // 处理请求错误
    console.error("请求错误:", error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 处理响应数据
    const res = response.data;
    if (res.statusCode === 200) {
      return res.data;
    } else {
      message.error(res.message || "Error");
      return Promise.reject(new Error(res.message || "Error"));
    }
  },
  (error) => {
    console.error("响应错误:", error);
    if (error.response && error.response.status === 401) {
      // message.error("身份验证过期，请重新登录");
      events.emit("logout");
    }
    return Promise.reject(error);
  }
);

export const post = (url, data) => service.post(url, data);
export const get = (url, params) => service.get(url, { params });

export const upload = (url, data, options = {}) => {
  const { onUploadProgress } = options;
  return service.post(url, data, {
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) {
        onUploadProgress(progressEvent);
      }
    },
  });
};

export default service;
