// history
export const YX = "YX";
export const JZ = "JZ";
export const KH = "KH";
export const historyTabsConfig = {
  [YX]: "影像数据",
  [JZ]: "就诊历史",
  [KH]: "客户信息",
};
export const history_tabs_options = Object.keys(historyTabsConfig).reduce(
  (list, key) => {
    list.push({ label: historyTabsConfig[key], key: key });
    return list;
  },
  []
);

export const ZX = "咨询";
const CZ = "初诊";
const FZ = "复诊";
const FC = "复查";
const QT = "其他";

export const visitTypeOptions = [
  {
    label: ZX,
    value: ZX,
  },
  {
    label: CZ,
    value: CZ,
  },
  {
    label: FZ,
    value: FZ,
  },
  {
    label: FC,
    value: FC,
  },
  {
    label: QT,
    value: QT,
  },
];

export const canHandleHistoryKeys = [CZ, FC];
