import { createSlice } from "@reduxjs/toolkit";
import { YX } from "@/options";
const counterSlice = createSlice({
  name: "history",
  initialState: {
    tabActiveKey: YX,
  },
  reducers: {
    setState(state, { payload }) {
      for (let key in payload) {
        state[key] = payload[key];
      }
    },
  },
});

export const {} = counterSlice.actions;
export default counterSlice.reducer;
