import styles from "./index.module.scss";
import {
  Divider,
  Button,
  Form,
  Space,
  Input,
  DatePicker,
  Segmented,
  Row,
  Col,
  Select,
  message,
} from "antd";
import events from "@/utils/events";
import { useEffect, useMemo, useRef, useState } from "react";
import cityData from "@/utils/city.json";
import dayjs from "dayjs";
import Title from "@/components/Title";
import { upsertCustomer } from "@/service";
import UploadCover from "@/components/UploadCover";
import Distance from "@/components/Distance";

const defaultProvince = cityData[0].name;
const defaultCity = cityData[0].children[0].name;
const defaultDistrict = cityData[0].children[0].children[0].name;
const defaultHasHistroy = "否";
const CustormForm = ({ onCommit }) => {
  const [data, setData] = useState(null);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [province, setProvince] = useState(defaultProvince);
  const [city, setCity] = useState(defaultCity);
  const [hasHistory, setHasHistory] = useState(defaultHasHistroy);
  const [loading, setLoading] = useState(false);
  const photoRef = useRef();
  const cityList = useMemo(() => {
    return cityData.find((item) => item.name === province)?.children || [];
  }, [province]);
  const districtList = useMemo(() => {
    return cityList.find((item) => item.name === city)?.children || [];
  }, [city, cityList]);
  function openFn(data) {
    setData(data);
    form.setFieldsValue({ ...data, date_of_birth: dayjs(data.date_of_birth) });
    setOpen(true);
  }
  function closeFn() {
    setOpen(false);
    form.resetFields();
  }
  async function commit() {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const params = form.getFieldsValue();
        params.date_of_birth = params.date_of_birth?.format("YYYY-MM-DD");
        try {
          const id = await upsertCustomer(params);
          message.success(params.id ? "更新成功" : "新建成功");
          form.setFieldsValue({ id: id });
          setTimeout(() => {
            photoRef.current?.upload();
            closeFn();
          }, 16);
          onCommit(id);
        } catch (err) {}
        setLoading(false);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    events.on("custorm-form-open", openFn);
    return () => {
      events.removeListener("custorm-form-open", openFn);
    };
  }, []);
  useEffect(() => {
    if (!open) closeFn();
  }, [open]);
  return (
    <div className={`${styles.box} ${open ? styles.open : styles.close}`}>
      <div className={styles.content}>
        <Title title={data?.customer_name ?? "新建客户"}></Title>
        <Distance col={40}></Distance>
        <div className={styles.scorll}>
          {data && open && (
            <div className={styles.img}>
              <UploadCover
                customer_id={data.id}
                src={data?.cover_path}
                ref={photoRef}
                id={data?.img_id}
                isCover={2}
              ></UploadCover>
            </div>
          )}
          <Distance col={40}></Distance>
          <Form form={form} labelCol={{ className: "form-label" }}>
            <Row gutter={20} style={{ width: "100%" }}>
              <Col span={6}>
                <Form.Item label="档案编号" name="euid">
                  <span>{data?.euid}</span>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="客户姓名"
                  name="customer_name"
                  rules={[{ required: true, message: "请输入客户姓名" }]}
                >
                  <Input placeholder="请输入客户姓名" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="出生日期"
                  name="date_of_birth"
                  // rules={[{ required: true, message: "请输入出生日期" }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format={"YYYY-MM-DD"}
                    placeholder="请选择出生日期"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="联系电话"
                  name="phone_number"
                  rules={[
                    { required: true, message: "请输入联系电话" },
                    {
                      pattern: /^1[3456789]\d{9}$/,
                      message: "请输入正确的手机号",
                    },
                  ]}
                >
                  <Input placeholder="请输入联系电话" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="性别" name="gender" initialValue={"男"}>
                  <Segmented options={["男", "女", "其他(她)"]}></Segmented>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="是否生育"
                  name="has_children"
                  initialValue={"未生育"}
                >
                  <Segmented options={["未生育", "生育"]}></Segmented>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="紧急联系人" name="emergency_contact_name">
                  <Input placeholder="请输入紧急联系人" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="紧急电话" name="emergency_contact_phone">
                  <Input placeholder="请输入紧急电话" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="地址">
                  <Space.Compact block>
                    <Form.Item
                      noStyle
                      name="province"
                      initialValue={defaultProvince}
                      style={{
                        width: "20%",
                      }}
                    >
                      <Select
                        onChange={(val, { list }) => {
                          setProvince(val);
                          setCity(list?.[0].name);
                          form.setFieldsValue({
                            city: list?.[0].name,
                            district: list?.[0].children?.[0]?.name,
                          });
                        }}
                        showSearch
                      >
                        {cityData.map((item) => (
                          <Select.Option
                            key={item.name}
                            value={item.name}
                            list={item.children}
                          >
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle name="city" initialValue={defaultCity}>
                      <Select
                        onChange={(val, { list }) => {
                          setCity(val);
                          form.setFieldsValue({
                            district: list?.[0].name,
                          });
                        }}
                        showSearch
                      >
                        {cityList.map((item) => (
                          <Select.Option
                            key={item.name}
                            value={item.name}
                            list={item.children}
                          >
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name="district"
                      initialValue={defaultDistrict}
                    >
                      <Select showSearch>
                        {districtList.map((item) => (
                          <Select.Option key={item.name} value={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="详细地址" name="detailed_address">
                  <Input.TextArea placeholder="请输入详细地址" />
                </Form.Item>
              </Col>
              <Col span={hasHistory == "是" ? 24 : 6}>
                <Form.Item label="医美历史">
                  <Space.Compact block style={{ width: "100%" }}>
                    <Form.Item
                      name="past_aesthetic_history"
                      initialValue={defaultHasHistroy}
                      noStyle
                    >
                      <Segmented
                        options={["否", "是"]}
                        onChange={setHasHistory}
                      ></Segmented>
                    </Form.Item>
                    {hasHistory === "是" && (
                      <Form.Item name="aesthetic_history_notes" noStyle>
                        <Input.TextArea rows={4} placeholder="请输入医美历史" />
                      </Form.Item>
                    )}
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="既往病史" name="medical_history">
                  <Input.TextArea rows={4} placeholder="请输入既往病史" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="过敏史" name="allergic_history">
                  <Input.TextArea rows={4} placeholder="请输入过敏史" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="病例" name="medical_records">
                  <Input.TextArea rows={4} placeholder="请输入病例" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item noStyle name="id"></Form.Item>
          </Form>
        </div>
        <div className={styles.btns}>
          <Space>
            <Button
              size="large"
              type="primary"
              onClick={commit}
              loading={loading}
              style={{ marginRight: 40, paddingLeft: 20, paddingRight: 20 }}
            >
              提交
            </Button>
            <Button
              size="large"
              onClick={closeFn}
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              取消
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};
export default CustormForm;
