import styles from "./index.module.scss";
import CustormItem from "./custormItem";
const Content = (props) => {
  const { view, list, isLoadedEnd, next } = props;
  return (
    <div className={styles.content}>
      {list.map((item, index) => (
        <CustormItem
          data={item}
          key={item.id}
          isLoadedEnd={isLoadedEnd}
          next={next}
          isEnd={index == list.length - 1}
        />
      ))}
    </div>
  );
};
export default Content;
