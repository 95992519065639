import React, { createContext, useState } from "react";
const HeaderContext = createContext(null);
const HeaderProvider = ({ children }) => {
  const [headerRender, setHeaderRender] = useState(null);
  return (
    <HeaderContext.Provider value={{ headerRender, setHeaderRender }}>
      {children}
    </HeaderContext.Provider>
  );
};
export { HeaderContext, HeaderProvider };
