import Search from "./components/search";
import styles from "./index.module.scss";
import Distance from "@/components/Distance";
import { useEffect, useState } from "react";
import { getVisitHistory, getCustomers } from "@/service/index";
import TotalMsg from "./components/totalMsg";
import Content from "./components/content";
import CustormForm from "./components/custormForm";
import { Spin } from "antd";
const size = 10;
const Index = () => {
  const [list, setList] = useState([]);
  // 视角
  const [view, setView] = useState("kh");
  const [loading, setloading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoadedEnd, setIsEnd] = useState(false);
  const [search, setSearch] = useState({
    treatment_area: "",
    treatment_item: "",
    search_query: "",
    sort: 0,
  });
  function searchFn() {
    setList([]);
    setIsEnd(false);
    setTotal(0);
    if (page != 1) setPage(1);
    else getList();
  }
  async function getList() {
    const params = {
      ...search,
      page: page,
      page_size: size,
      order: 1,
    };
    // 如果search_query为中文，设置为customer_name，如果为数字，设置为phone_number
    if (search.search_query) {
      if (/^[\u4e00-\u9fa5]+$/.test(search.search_query)) {
        params.name = search.search_query;
      } else {
        params.phone_number = search.search_query;
      }
    }
    delete params.search_query;
    const request = view == "kh" ? getCustomers : getVisitHistory;
    try {
      const { data, total_count } = await request(params);
      setTotal(total_count);
      if (data.length < size) setIsEnd(true);
      setList((prev) => [...prev, ...data]);
    } catch (err) {}
  }
  useEffect(() => {
    getList();
  }, [page]);
  return (
    <div className={styles.box}>
      <Search search={search} setSearch={setSearch} searchFn={searchFn} />
      {view == "kh" && <CustormForm onCommit={searchFn} />}
      {/* <Distance /> */}
      {/* {view == "ym" && <BeautyTypes search={search} setSearch={setSearch} />} */}
      <Distance />
      <TotalMsg total={total} view={view} />
      <Distance />
      <div className={styles.contentBox}>
        <Spin spinning={loading}>
          <Content
            view={view}
            list={list}
            next={() => setPage(page + 1)}
            isLoadedEnd={isLoadedEnd}
          />
        </Spin>
      </div>
    </div>
  );
};
export default Index;
