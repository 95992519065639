import { useSelector } from "react-redux";
import styles from "./index.module.scss";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { HeaderContext } from "./HeaderContext";
import { useContext } from "react";
import icon from "@/assets/icon";
import { fullScreen } from "@/utils/function";
import events from "@/utils/events";
import { PoweroffOutlined } from "@ant-design/icons";
const Header = () => {
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const { headerRender } = useContext(HeaderContext);
  function goIndex() {
    navigate("/index");
  }
  function logout() {
    Modal.confirm({
      title: "提示",
      type: "warning",
      content: "确定要退出吗？",
      onOk: () => events.emit("logout"),
      onCancel: () => {},
      okText: "确定",
      cancelText: "取消",
    });
  }
  return (
    <div className={styles.header}>
      <div className={styles.left}>
        <div className={styles.title}>医用影像档案管理系统</div>
      </div>
      <div className={styles.right}>
        <div className={styles.username}>
          <Button
            type="text"
            style={{ color: "#fff" }}
            icon={<PoweroffOutlined />}
            onClick={logout}
          >
            {userInfo.name}
          </Button>
        </div>
        {headerRender}
        <div className={styles.exit}>
          <Button
            icon={
              <img
                src={icon.quanping2}
                style={{ transform: "translateY(2px) scale(0.8)" }}
              />
            }
            type="text"
            style={{ color: "#fff" }}
            onClick={() => fullScreen()}
          >
            全屏
          </Button>
          <Button
            icon={
              <img
                src={icon.shouye}
                style={{ transform: "translateY(2px) scale(0.8)" }}
              />
            }
            type="text"
            style={{ color: "#fff" }}
            onClick={goIndex}
          >
            首页
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Header;
