import {
  HomeOutlined,
  TeamOutlined,
  ToolOutlined,
  BoldOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { lazy, useEffect } from "react";
import PrivateRoute from "./PrivateRoute";
import Layout from "@/layout";
import { Navigate } from "react-router-dom";
import store from "@/store";
import Index from "@/pages/Index";
import Login from "@/pages/Login";
const HistoryIndex = lazy(() => import("@/pages/HistoryIndex"));
const History = lazy(() => import("@/pages/History"));
const User = lazy(() => import("@/pages/User"));
const System = lazy(() => import("@/pages/System"));
const CreateCustomer = lazy(() => import("@/pages/CreateCustomer"));
{
  /* <PoweroffOutlined /> */
}
export const routes = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Layout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/",
        element: <Navigate to="/index" replace />,
        hidden: true,
      },
      {
        path: "/index",
        element: <Index />,
        title: "客户档案",
        icon: <HomeOutlined />,
      },
      {
        path: "/historyIndex",
        element: <HistoryIndex />,
        title: "医美档案",
        icon: <BoldOutlined />,
      },
      {
        path: "/createCustomer",
        element: <CreateCustomer />,
        title: "客户建档",
        icon: <UserAddOutlined />,
      },
      {
        path: "/history",
        element: <History />,
        title: "就诊历史",
        hidden: true,
        routeHidden: true,
      },
      {
        path: "/user",
        element: <User />,
        title: "用户管理",
        icon: <TeamOutlined />,
        isAdmin: true,
      },
      {
        path: "/system",
        element: <System />,
        title: "系统配置",
        icon: <ToolOutlined />,
        isAdmin: true,
      },
    ],
  },
  {
    path: "/login",
    element: <Login replace />,
  },
];

export function getRoute() {
  const { is_admin } = store.getState().userInfo;
  const { children } = routes.find((item) => item.path == "/");
  let domRoutes = children.filter((item) => !item.hidden);
  if (is_admin != 1) domRoutes = domRoutes.filter((item) => !item.isAdmin);
  const allRoutes = children;
  return {
    domRoutes,
    allRoutes,
  };
}
