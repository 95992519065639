// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-card-body {
  padding: var(--box-padding) !important;
  position: relative;
}
.ant-divider {
  margin-top: 16px !important;
  margin-bottom: var(--box-padding) !important;
}

.form-label {
  width: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/antd.scss"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,kBAAkB;AACpB;AACA;EACE,2BAA2B;EAC3B,4CAA4C;AAC9C;;AAEA;EACE,YAAY;AACd","sourcesContent":[".ant-card-body {\r\n  padding: var(--box-padding) !important;\r\n  position: relative;\r\n}\r\n.ant-divider {\r\n  margin-top: 16px !important;\r\n  margin-bottom: var(--box-padding) !important;\r\n}\r\n\r\n.form-label {\r\n  width: 120px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
