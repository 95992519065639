import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import { useSelector } from "react-redux";
import { useMemo } from "react";
const App = () => {
  const { is_admin } = useSelector((state) => state.userInfo);
  const routesList = useMemo(() => {
    const list = routes
      .map((route) => {
        if (is_admin == 1) return route;
        else if (is_admin == 0 && route.isAdmin) return null;
        else return route;
      })
      .filter(Boolean);
    return list;
  }, [is_admin]);
  return (
    <Router>
      <Routes>
        {routesList.map((route) => (
          <Route key={route.path} path={route.path} element={route.element}>
            {route.children &&
              route.children.map((child) => (
                <Route
                  key={child.path}
                  path={child.path}
                  element={child.element}
                />
              ))}
          </Route>
        ))}
      </Routes>
    </Router>
  );
};

export default App;
