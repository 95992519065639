import styles from "./index.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { getRoute } from "@/routes/routes";
import { useRef } from "react";

const RoutesCom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const childrenRef = useRef(getRoute().domRoutes);
  return (
    <div className={styles.routeBox}>
      {childrenRef.current.map((item) => {
        return (
          <div
            className={`${styles.routeItem} ${
              location.pathname == item.path ? styles.active : ""
            }`}
            key={item.path}
            onClick={() => navigate(item.path)}
          >
            <div className={styles.icon}>{item.icon}</div>
            <div className={styles.title}>{item.title}</div>
          </div>
        );
      })}
    </div>
  );
};
export default RoutesCom;
